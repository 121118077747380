@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/animate.css@4.1.1/animate.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-box {
  @apply appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none;
}
.validation-error {
  @apply text-red-500 text-xs italic;
}
.label {
  @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2;
}
